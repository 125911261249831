import React, { useEffect } from "react";
import "./App.css";
import Header from "./components/Header";
import Services from "./components/Services";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

function App() {
  useEffect(() => {
    console.log(
      "%cThis project was developed by Maurice Brian Oluoch.",
      "color: green; font-size: 16px;"
    );
    console.log(
      "%cFeel free to reach out to me at:",
      "color: black; font-size: 14px;"
    );
    console.log(
      "%cEmail: brianmauriceoluoch@gmail.com",
      "color: red; font-size: 14px;"
    );
    console.log(
      "%cLinkedIn: https://www.linkedin.com/in/maurice-brian-oluoch-b50987299/",
      "color: purple; font-size: 14px;"
    );
    console.log(
      "%cGitHub: https://github.com/blvck-code",
      "color: orange; font-size: 14px;"
    );

    // Optional: Add more information or links
    console.log(
      "%cThank you for checking out my project!",
      "color: blue; font-size: 16px; font-weight: bold;"
    );
  }, []);

  return (
    <main className="bg-white">
      <Navbar />
      <Header />
      <Services />
      <Partners />
      <Footer />
    </main>
  );
}

export default App;
