import service from "../assets/img/services.png";
import React from "react";

const Services = () => {
  return (
    <section
      id="services"
      className="w-full py-5 px-4 md:px-8 bg-gradient-to-b from-[#FCFCFC]/[100%] via-[#E1E5E9]/[0.7] to-[#CDD2D8]/[0.3]"
    >
      <div className="max-w-screen-2xl mx-auto">
        <div className="flex flex-col items-center py-8 max-w-[904px] mx-auto">
          <h1 className="large-text limit">
            Our Range of <span>Energy</span> Solutions
          </h1>
          <p className="text-center text-sm md:text-[20px] font-normal text-[#212121]">
            Explore our extensive range of energy solutions designed to deliver
            reliable power whenever you need it:
          </p>
        </div>
        <div className="grid grid-cols-1 mx-auto max-w-screen-xl sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-x-10 justify-center md:py-8">
          <div className="service-item">
            <svg
              width="55"
              height="55"
              viewBox="0 0 55 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="55"
                height="55"
                rx="27.5"
                fill="url(#paint0_linear_15_2238)"
              />
              <path
                d="M35.6667 15H32.3333C31.9651 15 31.6667 15.2985 31.6667 15.6667V15.7452L36.3333 19.4785V15.6667C36.3333 15.2985 36.0349 15 35.6667 15Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.3333 23.6667C25.3333 22.7462 26.0795 22 27 22C27.9205 22 28.6667 22.7462 28.6667 23.6667C28.6667 24.5871 27.9205 25.3333 27 25.3333C26.0795 25.3333 25.3333 24.5871 25.3333 23.6667Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.6667 25.614L39.7086 26.4475C40.1399 26.7925 40.7692 26.7226 41.1142 26.2914C41.4592 25.8601 41.3893 25.2308 40.958 24.8858L30.1235 16.2182C28.2974 14.7573 25.7026 14.7573 23.8765 16.2182L13.042 24.8858C12.6107 25.2308 12.5408 25.8601 12.8858 26.2914C13.2308 26.7226 13.8601 26.7925 14.2914 26.4475L15.3333 25.614V39.3333H13.6667C13.1144 39.3333 12.6667 39.781 12.6667 40.3333C12.6667 40.8856 13.1144 41.3333 13.6667 41.3333H40.3333C40.8856 41.3333 41.3333 40.8856 41.3333 40.3333C41.3333 39.781 40.8856 39.3333 40.3333 39.3333H38.6667V25.614ZM23.3333 23.6667C23.3333 21.6416 24.975 20 27 20C29.025 20 30.6667 21.6416 30.6667 23.6667C30.6667 25.6917 29.025 27.3333 27 27.3333C24.975 27.3333 23.3333 25.6917 23.3333 23.6667ZM27.0658 28.6667C27.9523 28.6666 28.7161 28.6666 29.3278 28.7488C29.9833 28.8369 30.6117 29.0357 31.1213 29.5453C31.631 30.055 31.8297 30.6834 31.9179 31.3389C31.995 31.9123 31.9997 32.6195 32 33.4361C32 33.4905 32 33.5454 32 33.6008L32 39.3333H30V33.6667C30 32.6956 29.9979 32.0679 29.9357 31.6054C29.8774 31.1716 29.7832 31.0357 29.7071 30.9596C29.631 30.8834 29.495 30.7893 29.0613 30.731C28.5988 30.6688 27.9711 30.6667 27 30.6667C26.0289 30.6667 25.4012 30.6688 24.9387 30.731C24.505 30.7893 24.369 30.8834 24.2929 30.9596C24.2168 31.0357 24.1226 31.1716 24.0643 31.6054C24.0021 32.0679 24 32.6956 24 33.6667V39.3333H22L22 33.6008C21.9999 32.7143 21.9999 31.9505 22.0821 31.3389C22.1703 30.6834 22.369 30.055 22.8787 29.5453C23.3883 29.0357 24.0167 28.8369 24.6722 28.7488C25.2839 28.6666 26.0477 28.6666 26.9342 28.6667H27.0658Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.3333 23.6667C25.3333 22.7462 26.0795 22 27 22C27.9205 22 28.6667 22.7462 28.6667 23.6667C28.6667 24.5871 27.9205 25.3333 27 25.3333C26.0795 25.3333 25.3333 24.5871 25.3333 23.6667Z"
                fill="black"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_15_2238"
                  x1="0"
                  y1="27.5"
                  x2="55"
                  y2="27.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#DEFF34" />
                  <stop offset="1" stopColor="#8CF97C" />
                </linearGradient>
              </defs>
            </svg>
            <h2 className="font-bold py-3 md:py-4 text-lg md:text-[30px]">
              Solar power solutions
            </h2>
            <p className="text-xs md:text-sm">
              Protect your home and family with our range of residential
              generators. From compact models for essential appliances to
              whole-house systems, we have solutions to keep your home powered
              during outages.
            </p>
          </div>
          <div className="service-item">
            <svg
              width="55"
              height="55"
              viewBox="0 0 55 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="55"
                height="55"
                rx="27.5"
                fill="url(#paint0_linear_15_2242)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31 13.6667H33.6667C36.1808 13.6667 37.4379 13.6667 38.2189 14.4478C39 15.2288 39 16.4859 39 19.0001V39.3334H40.3333C40.8856 39.3334 41.3333 39.7811 41.3333 40.3334C41.3333 40.8857 40.8856 41.3334 40.3333 41.3334H13.6667C13.1144 41.3334 12.6667 40.8857 12.6667 40.3334C12.6667 39.7811 13.1144 39.3334 13.6667 39.3334H15V23.0001C15 20.4859 15 19.2288 15.781 18.4478C16.5621 17.6667 17.8192 17.6667 20.3333 17.6667H25.6667C28.1808 17.6667 29.4379 17.6667 30.2189 18.4478C31 19.2288 31 20.4859 31 23.0001V39.3334H33V23.0001L33 22.8808C33.0001 21.7266 33.0003 20.6684 32.8845 19.8075C32.7571 18.8596 32.4572 17.8576 31.6332 17.0336C30.8091 16.2095 29.8072 15.9097 28.8592 15.7822C28.0105 15.6681 26.9699 15.6666 25.8347 15.6667C25.9471 15.1504 26.1346 14.7609 26.4477 14.4478C27.2288 13.6667 28.4858 13.6667 31 13.6667ZM18 21.6667C18 21.1145 18.4477 20.6667 19 20.6667H27C27.5523 20.6667 28 21.1145 28 21.6667C28 22.219 27.5523 22.6667 27 22.6667H19C18.4477 22.6667 18 22.219 18 21.6667ZM18 25.6667C18 25.1145 18.4477 24.6667 19 24.6667H27C27.5523 24.6667 28 25.1145 28 25.6667C28 26.219 27.5523 26.6667 27 26.6667H19C18.4477 26.6667 18 26.219 18 25.6667ZM18 29.6667C18 29.1145 18.4477 28.6667 19 28.6667H27C27.5523 28.6667 28 29.1145 28 29.6667C28 30.219 27.5523 30.6667 27 30.6667H19C18.4477 30.6667 18 30.219 18 29.6667ZM23 35.3334C23.5523 35.3334 24 35.7811 24 36.3334V39.3334H22V36.3334C22 35.7811 22.4477 35.3334 23 35.3334Z"
                fill="black"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_15_2242"
                  x1="0"
                  y1="27.5"
                  x2="55"
                  y2="27.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#DEFF34" />
                  <stop offset="1" stopColor="#8CF97C" />
                </linearGradient>
              </defs>
            </svg>

            <h2 className="font-bold py-3 md:py-4 text-lg md:text-[30px]">
              Commercial & Industrial Generators
            </h2>
            <p className="text-xs md:text-sm">
              Keep your business operations running smoothly with our commercial
              generator solutions. Whether you need backup power for a small
              office or a large commercial facility, our generators are designed
              to meet your specific requirements.
            </p>
          </div>
          <div className="service-item">
            <svg
              width="55"
              height="55"
              viewBox="0 0 55 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="55"
                height="55"
                rx="27.5"
                fill="url(#paint0_linear_15_2246)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.3333 13.6667H24C20.2288 13.6667 18.3431 13.6667 17.1716 14.8383C16 16.0099 16 17.8955 16 21.6667V39.3334H14.6667C14.1144 39.3334 13.6667 39.7811 13.6667 40.3334C13.6667 40.8857 14.1144 41.3334 14.6667 41.3334H35C35.5523 41.3334 36 40.8857 36 40.3334C36 39.7811 35.5523 39.3334 35 39.3334H33.3333V34.6668H35.4286C35.9283 34.6668 36.3333 35.0719 36.3333 35.5715V35.6668C36.3333 37.3236 37.6765 38.6668 39.3333 38.6668C40.9902 38.6668 42.3333 37.3236 42.3333 35.6668V21.1358C42.3334 20.9269 42.3334 20.7828 42.3255 20.6422C42.2513 19.3209 41.6562 18.0829 40.6708 17.1996C40.566 17.1056 40.4535 17.0156 40.2904 16.8851L38.6247 15.5526C38.1934 15.2076 37.5641 15.2775 37.2191 15.7088C36.8741 16.14 36.944 16.7693 37.3753 17.1143L39.0194 18.4296C39.2121 18.5837 39.2781 18.637 39.3358 18.6888C39.9271 19.2188 40.2841 19.9616 40.3286 20.7544C40.333 20.8319 40.3333 20.9167 40.3333 21.1634V21.6667H39.3333C38.2288 21.6667 37.3333 22.5622 37.3333 23.6667V26.8919C37.3333 27.7528 37.8842 28.517 38.7009 28.7893L40.3333 29.3334V35.6668C40.3333 36.2191 39.8856 36.6668 39.3333 36.6668C38.7811 36.6668 38.3333 36.2191 38.3333 35.6668V35.5715C38.3333 33.9673 37.0328 32.6668 35.4286 32.6668H33.3333V21.6667C33.3333 17.8955 33.3333 16.0099 32.1618 14.8383C30.9902 13.6667 29.1046 13.6667 25.3333 13.6667ZM25.1812 23.4759C25.6547 23.7601 25.8083 24.3743 25.5242 24.8479L24.4329 26.6667H26.6667C27.0269 26.6667 27.3594 26.8605 27.5369 27.174C27.7144 27.4876 27.7095 27.8723 27.5242 28.1812L25.5242 31.5146C25.24 31.9882 24.6258 32.1417 24.1522 31.8576C23.6786 31.5734 23.525 30.9592 23.8092 30.4856L24.9005 28.6667H22.6667C22.3064 28.6667 21.974 28.473 21.7965 28.1594C21.619 27.8459 21.6238 27.4612 21.8092 27.1523L23.8092 23.8189C24.0933 23.3453 24.7076 23.1918 25.1812 23.4759Z"
                fill="#1C274C"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_15_2246"
                  x1="0"
                  y1="27.5"
                  x2="55"
                  y2="27.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#DEFF34" />
                  <stop offset="1" stop-color="#8CF97C" />
                </linearGradient>
              </defs>
            </svg>

            <h2 className="font-bold py-3 md:py-4 text-lg md:text-[30px]">
              E- Mobility Solutions
            </h2>
            <p className="text-xs md:text-sm">
              Power critical equipment and machinery with our robust industrial
              generators. Built for durability and performance, our industrial
              solutions ensure continuous operation in demanding environments.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center py-8 mx-auto">
          <h1 className="large-text limit py-5">
            Why Choose <span>Zinbi Energies?</span>
          </h1>
          <div className="services-content py-10 px-3 rounded-[20px] w-full grid grid-cols-1 md:grid-cols-2 ">
            <img className="" src={service} alt="" />
            <div className="flex flex-col px-3 md:px-0 py-4 justify-between h-full">
              <div className="border-b-2 max-w-[600px] h-full flex items-start py-6 gap-x-6">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="48"
                    height="48"
                    rx="24"
                    fill="url(#paint0_linear_15_2212)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 37.3333C31.364 37.3333 37.3333 31.364 37.3333 24C37.3333 16.636 31.364 10.6666 24 10.6666C16.636 10.6666 10.6667 16.636 10.6667 24C10.6667 31.364 16.636 37.3333 24 37.3333ZM28.7147 19.2853C28.965 19.0353 29.3045 18.895 29.6583 18.8952C30.0121 18.8955 30.3513 19.0363 30.6013 19.2866C30.8513 19.537 30.9917 19.8764 30.9914 20.2302C30.9912 20.5841 30.8504 20.9233 30.6 21.1733L23.0627 28.7106L23.0573 28.716C22.9338 28.8402 22.7869 28.9388 22.6251 29.0061C22.4634 29.0734 22.2899 29.108 22.1147 29.108C21.9395 29.108 21.766 29.0734 21.6042 29.0061C21.4424 28.9388 21.2955 28.8402 21.172 28.716L21.1667 28.7106L17.4 24.944C17.2727 24.821 17.1711 24.6738 17.1012 24.5112C17.0313 24.3485 16.9945 24.1735 16.993 23.9965C16.9915 23.8195 17.0252 23.6439 17.0922 23.48C17.1593 23.3162 17.2583 23.1673 17.3835 23.0421C17.5087 22.9169 17.6575 22.8179 17.8214 22.7509C17.9853 22.6838 18.1608 22.6501 18.3379 22.6516C18.5149 22.6532 18.6899 22.6899 18.8525 22.7598C19.0152 22.8297 19.1623 22.9313 19.2853 23.0586L22.1147 25.8866L28.7147 19.2866V19.2853Z"
                    fill="black"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_15_2212"
                      x1="0"
                      y1="24"
                      x2="48"
                      y2="24"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#DEFF34" />
                      <stop offset="1" stopColor="#8CF97C" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="w-full max-w-[445px]">
                  <h1 className="font-bold mb-2.5 md:mb-4 text-base md:text-[24px] text-[#202939]">
                    Expertise and Experience
                  </h1>
                  <p className="text-sm md:text-base">
                    With years of industry experience, we have the knowledge and
                    expertise to recommend the right generator solution for your
                    unique needs.
                  </p>
                </div>
              </div>
              <div className="border-b-2 max-w-[600px] h-full flex items-start py-6 gap-x-6">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="48"
                    height="48"
                    rx="24"
                    fill="url(#paint0_linear_15_2212)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 37.3333C31.364 37.3333 37.3333 31.364 37.3333 24C37.3333 16.636 31.364 10.6666 24 10.6666C16.636 10.6666 10.6667 16.636 10.6667 24C10.6667 31.364 16.636 37.3333 24 37.3333ZM28.7147 19.2853C28.965 19.0353 29.3045 18.895 29.6583 18.8952C30.0121 18.8955 30.3513 19.0363 30.6013 19.2866C30.8513 19.537 30.9917 19.8764 30.9914 20.2302C30.9912 20.5841 30.8504 20.9233 30.6 21.1733L23.0627 28.7106L23.0573 28.716C22.9338 28.8402 22.7869 28.9388 22.6251 29.0061C22.4634 29.0734 22.2899 29.108 22.1147 29.108C21.9395 29.108 21.766 29.0734 21.6042 29.0061C21.4424 28.9388 21.2955 28.8402 21.172 28.716L21.1667 28.7106L17.4 24.944C17.2727 24.821 17.1711 24.6738 17.1012 24.5112C17.0313 24.3485 16.9945 24.1735 16.993 23.9965C16.9915 23.8195 17.0252 23.6439 17.0922 23.48C17.1593 23.3162 17.2583 23.1673 17.3835 23.0421C17.5087 22.9169 17.6575 22.8179 17.8214 22.7509C17.9853 22.6838 18.1608 22.6501 18.3379 22.6516C18.5149 22.6532 18.6899 22.6899 18.8525 22.7598C19.0152 22.8297 19.1623 22.9313 19.2853 23.0586L22.1147 25.8866L28.7147 19.2866V19.2853Z"
                    fill="black"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_15_2212"
                      x1="0"
                      y1="24"
                      x2="48"
                      y2="24"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#DEFF34" />
                      <stop offset="1" stopColor="#8CF97C" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="w-full max-w-[445px]">
                  <h1 className="font-bold mb-2.5 md:mb-4 text-base md:text-[24px] text-[#202939]">
                    Quality Assurance
                  </h1>
                  <p className="text-sm md:text-base">
                    We partner with leading generator manufacturers known for
                    their reliability and performance. Our generators are built
                    to last and undergo rigorous testing to ensure they meet the
                    highest standards.
                  </p>
                </div>
              </div>
              <div className="border-b-2 max-w-[600px] h-full flex items-start py-6 gap-x-6">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="48"
                    height="48"
                    rx="24"
                    fill="url(#paint0_linear_15_2212)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 37.3333C31.364 37.3333 37.3333 31.364 37.3333 24C37.3333 16.636 31.364 10.6666 24 10.6666C16.636 10.6666 10.6667 16.636 10.6667 24C10.6667 31.364 16.636 37.3333 24 37.3333ZM28.7147 19.2853C28.965 19.0353 29.3045 18.895 29.6583 18.8952C30.0121 18.8955 30.3513 19.0363 30.6013 19.2866C30.8513 19.537 30.9917 19.8764 30.9914 20.2302C30.9912 20.5841 30.8504 20.9233 30.6 21.1733L23.0627 28.7106L23.0573 28.716C22.9338 28.8402 22.7869 28.9388 22.6251 29.0061C22.4634 29.0734 22.2899 29.108 22.1147 29.108C21.9395 29.108 21.766 29.0734 21.6042 29.0061C21.4424 28.9388 21.2955 28.8402 21.172 28.716L21.1667 28.7106L17.4 24.944C17.2727 24.821 17.1711 24.6738 17.1012 24.5112C17.0313 24.3485 16.9945 24.1735 16.993 23.9965C16.9915 23.8195 17.0252 23.6439 17.0922 23.48C17.1593 23.3162 17.2583 23.1673 17.3835 23.0421C17.5087 22.9169 17.6575 22.8179 17.8214 22.7509C17.9853 22.6838 18.1608 22.6501 18.3379 22.6516C18.5149 22.6532 18.6899 22.6899 18.8525 22.7598C19.0152 22.8297 19.1623 22.9313 19.2853 23.0586L22.1147 25.8866L28.7147 19.2866V19.2853Z"
                    fill="black"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_15_2212"
                      x1="0"
                      y1="24"
                      x2="48"
                      y2="24"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#DEFF34" />
                      <stop offset="1" stopColor="#8CF97C" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="w-full max-w-[445px]">
                  <h1 className="font-bold mb-2.5 md:mb-4 text-base md:text-[24px] text-[#202939]">
                    Customer Support
                  </h1>
                  <p className="text-sm md:text-base">
                    From initial consultation to installation and ongoing
                    support, our team is dedicated to providing exceptional
                    customer service. We're here to help you every step of the
                    way.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
