import React from "react";

const Header = () => {
  return (
    <section className="header my-0 md:py-2 md:px-8">
      <div
        id="home"
        className="hero max-w-screen-2xl mx-auto h-[355px] sm:h-[450px] md:h-[686px]  md:rounded-[20px] flex items-end justify-center md:items-end md:justify-start w-full bg-blue-300 px-4 md:px-16 py-16 md:py-24"
      >
        <h1 className="text-[35px] sm:text-[50px] md:text-[65px] lg:text-[84px]  text-white md:text-left text-center md:leading-[88px] font-bold max-w-full md:max-w-[758px]">
          Powering Your World with Reliable Energy Solutions
        </h1>
      </div>
      <div
        id="about"
        className="flex flex-col relative items-center md:max-w-screen-2xl px-4 md:px-8 md:px-0 py-10 md:py-20 mx-auto overflow-hidden"
      >
        <h1 className="large-text">
          Trusted in <span>Energy Solutions.</span>
        </h1>
        <p className="text-center md:max-w-[904px] text-sm md:text-[22px] leading-tight font-normal text-[#212121]">
          Welcome to Zinbi energies, your premier source for high-quality energy
          solutions. We specialize in solar power solution, distribution of
          generators and also e-mobility solutions. With a strong commitment to
          reliability, efficiency, and customer satisfaction, we provide
          top-notch products and comprehensive service to ensure uninterrupted
          power supply.
        </p>
      </div>
    </section>
  );
};
// const Header = () => {
//   return (
//     <section className="header h-auto w-full md:py-4 md:px-8">
//       <div className="hero h-[355px] bg-red-400 relative md:h-[686px] mx-auto sm:w-full md:max-w-screen-2xl md:rounded-[20px] p-0 md:py-8  md:px-14 w-full">
//         <Navbar />
//         <h1 className="sm:max-w-[450px] mx-auto flex h-full  md:max-w-[758px] w-full text-white md:text-left text-center text-[35px] sm:text-[50px] md:text-[84px] md:leading-[88px] font-bold md:absolute bottom-20">
//           Powering Your World with Reliable Energy
//         </h1>
//       </div>
//       <div
//         id="about"
//         className="flex flex-col relative items-center md:max-w-screen-2xl  px-8 md:px-0 py-10 md:py-20 mx-auto overflow-hidden"
//       >
//         <h1 className="large-text">
//           Trusted in <span>Energy Solutions.</span>
//         </h1>
//         <p className="text-center md:max-w-[904px] text-sm md:text-[22px] leading-tight font-normal text-[#212121]">
//           Welcome to Zinbi energies, your premier source for high-quality
//           generator solutions. We specialize in the distribution of generators
//           tailored to meet the diverse needs of residential, commercial, and
//           industrial clients. With a strong commitment to reliability,
//           efficiency, and customer satisfaction, we provide top-notch products
//           and comprehensive service to ensure uninterrupted power supply.
//         </p>
//       </div>
//       {/*<div className="blue-circle"></div>*/}
//     </section>
//   );
// };

export default Header;
