import React from "react";
import partner from "../assets/img/partners.png";

const Partners = () => {
  return (
    <section
      id="partners"
      className="bg-white w-full py-8 md:py-14 overflow-hidden"
    >
      <div className="flex flex-col">
        <h1 className="font-bold text-[24px] md:text-[50px] text-center pb-4">
          Partners
        </h1>
        <div className="flex gap-x-5 flex-nowrap max-w-screen-2xl mx-auto">
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
        </div>
        <div className="flex gap-x-5 flex-nowrap max-w-screen-2xl mx-auto">
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
          <img
            className="w-[78px] h-[42px] md:w-[172px] md:h-[90px]"
            src={partner}
            alt="partners"
          />
        </div>
      </div>
    </section>
  );
};

export default Partners;
