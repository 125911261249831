import logo from "../assets/img/logo.png";
import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const closeNav = () => {
    setIsOpen(false);
  };

  return (
    <nav className=" fixed w-full top-0 m-0 md:top-10 z-10 h-[76px] md:h-[90px] md:px-14">
      <div className="max-w-screen-md md:max-w-screen-xl md:shadow-lg mx-auto bg-white h-full px-4 md:px-8 md:rounded-[10px] flex items-center justify-between">
        <div className="w-[86px] h-[34px] md:w-[148px] md:h-[58.7px]">
          <img src={logo} className="h-full w-full" alt="" />
        </div>
        <div
          className={`absolute md:static md:min-h-fit min-h-[308px] top-0 md:w-auto w-full  px-5 ${
            isOpen ? "left-0 mobile-nav" : "left-[-100%]"
          }`}
        >
          <button
            onClick={toggleNav}
            className="md:hidden absolute right-4 top-7"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 7L7 25"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25 25L7 7"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <div className="h-full flex items-center justify-center py-12">
            <ul className="flex md:flex-row flex-col items-center md:gap-[2vw] gap-5">
              <li>
                <a
                  className="hover:text-gray-500 text-[24px] md:text-lg"
                  href="#home"
                  onClick={closeNav}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  className="hover:text-gray-500 text-[24px] md:text-lg"
                  href="#about"
                  onClick={closeNav}
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  className="hover:text-gray-500 text-[24px] md:text-lg"
                  href="#services"
                  onClick={closeNav}
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  className="hover:text-gray-500 text-[24px] md:text-lg"
                  href="#partners"
                  onClick={closeNav}
                >
                  Partner
                </a>
              </li>
              <li>
                <a
                  className="hover:text-gray-500 text-[24px] md:text-lg"
                  href="#contact"
                  onClick={closeNav}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div />
        <span></span>
        <button onClick={toggleNav} className="md:hidden">
          <svg
            width="26"
            height="18"
            viewBox="0 0 26 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              y="15.4277"
              width="26"
              height="2.57155"
              rx="1.28577"
              fill="black"
            />
            <rect
              y="7.71484"
              width="26"
              height="2.57155"
              rx="1.28577"
              fill="black"
            />
            <rect width="26" height="2.57155" rx="1.28577" fill="black" />
          </svg>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;

{
  /*<div className="absolute min-h-[60vh] w-full mobile-nav left-0 top-[9%] z-10 flex md:items-center">*/
}
{
  /*<ul className="flex flex-col md:flex-row items-center gap-[4vw] gap-x-10">*/
}
{
  /*  <li>*/
}
{
  /*    <a className="text-lg font-[500]" href="">*/
}
{
  /*      Home*/
}
{
  /*    </a>*/
}
{
  /*  </li>*/
}
{
  /*  <li>*/
}
{
  /*    <a className="text-lg font-[500]" href="">*/
}
{
  /*      About Us*/
}
{
  /*    </a>*/
}
{
  /*  </li>*/
}
{
  /*  <li>*/
}
{
  /*    <a className="text-lg font-[500]" href="">*/
}
{
  /*      Services*/
}
{
  /*    </a>*/
}
{
  /*  </li>*/
}
{
  /*  <li>*/
}
{
  /*    <a className="text-lg font-[500]" href="">*/
}
{
  /*      Partners*/
}
{
  /*    </a>*/
}
{
  /*  </li>*/
}
{
  /*  <li>*/
}
{
  /*    <a className="text-lg font-[500]" href="">*/
}
{
  /*      Contact Us*/
}
{
  /*    </a>*/
}
{
  /*  </li>*/
}
{
  /*</ul>*/
}
{
  /*</div>*/
}
