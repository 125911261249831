import logoBlvck from "../assets/img/logo-blvck.png";
import React from "react";

const Footer = () => {
  return (
    <footer id="contact" className="bg-black w-full pt-4 px-4 md:px-8">
      <div className="w-full max-w-screen-2xl mx-auto relative">
        <div className="relative flex flex-col md:flex-row  gap-y-10 justify-between gap-x-8 py-6 md:py-10">
          <h1 className="w-full lg:w-[70%] text-center px-4 md:px-0 md:text-left text-[24px] md:text-[45px] lg:text-[52px] leading-[28px] md:leading-[48px] lg:leading-[62px] font-[500] bg-gradient-to-r from-[#DEFF34] to-[#8CF97C] text-transparent bg-clip-text">
            Contact Zinbi Energies for expert advice and the perfect generator
            solution tailored to your needs.
          </h1>
          <div className="static lg:absolute right-0 px-2 md:px-5 py-4 md:top-[-100%] lg:top-[-35%] rounded-[16px] w-full bg-black lg:w-[30%]">
            <form className="mx-auto flex flex-col px-5 py-4 gap-4 justify-between bg-white rounded-[16px] w-full md:w-full">
              <input
                className="w-full bg-[#212121]/[8%] py-3 md:py-5 px-5 rounded-[8px] text-lg md:text-[20px]"
                type="text"
                placeholder="Phone Number"
              />
              <input
                className="w-full bg-[#212121]/[8%] py-3 md:py-5 px-5 rounded-[8px] text-lg md:text-[20px]"
                type="text"
                placeholder="Your Email"
              />
              <button className="py-3 md:py-5 text-lg font-[700] px-5 rounded-[8px] w-full flex items-center justify-center gap-x-3 bg-gradient-to-r from-[#DEFF34] to-[#8CF97C]">
                Submit{" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.25 3.75V5H14.1187L3.75 15.3687L4.63125 16.25L15 5.88125V13.75H16.25V3.75H6.25Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.5"
                  />
                </svg>
              </button>
            </form>
          </div>
        </div>
        <div className="flex flex-col rounded-t-[30px] w-full bg-[#DEFF34]">
          <div className="flex-1 grid grid-col-1 gap-y-4 md:grid-cols-3 px-6 md:px-10 py-8">
            <div className="h-full w-full flex flex-col gap-8">
              <img
                className="w-[148px] h-[58px]"
                src={logoBlvck}
                alt="Zinbi Logo"
              />
              <div className="flex flex-col gap-y-1">
                <p className="font-[500] text-base">
                  Sky Park Plaza, Westlands.
                </p>
                <p className="font-[500] text-base">
                  Woodvale close, Nairobi, Kenya.
                </p>
                <p className="font-[500] text-base">Tel: +254 7400 42992</p>
                <p className="font-[500] text-base">
                  Email: info@zinbi-energies.com
                </p>
              </div>
            </div>
            <div />
            <div className="flex flex-col md:flex-row gap-y-4 items-start justify-between h-full w-full">
              <ul className="h-full flex flex-col justify-between">
                <li>
                  <a className="text-lg font-[500]" href="#about">
                    Home
                  </a>
                </li>
                <li>
                  <a className="text-lg font-[500]" href="#about">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="text-lg font-[500]" href="#services">
                    Services
                  </a>
                </li>
                <li>
                  <a className="text-lg font-[500]" href="#partners">
                    Partners
                  </a>
                </li>
                <li>
                  <a className="text-lg font-[500]" href="#contact">
                    Contact Us
                  </a>
                </li>
              </ul>
              <div className="flex gap-x-4">
                <a href="">
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.6429 2H4.35714C3.0558 2 2 3.0558 2 4.35714V21.6429C2 22.9442 3.0558 24 4.35714 24H21.6429C22.9442 24 24 22.9442 24 21.6429V4.35714C24 3.0558 22.9442 2 21.6429 2ZM19.2415 9.79821C19.2513 9.93571 19.2513 10.0781 19.2513 10.2156C19.2513 14.4732 16.0103 19.379 10.0879 19.379C8.26116 19.379 6.56696 18.8487 5.14286 17.9353C5.40313 17.9647 5.65357 17.9746 5.91875 17.9746C7.42634 17.9746 8.81116 17.4638 9.91607 16.5996C8.50179 16.5701 7.31339 15.642 6.9058 14.3652C7.40179 14.4388 7.84866 14.4388 8.35938 14.3062C6.88616 14.0067 5.78125 12.7103 5.78125 11.1437V11.1045C6.20848 11.3451 6.70937 11.4924 7.23482 11.5121C6.79307 11.2182 6.43093 10.8196 6.18072 10.3517C5.93052 9.88388 5.80002 9.36136 5.80089 8.8308C5.80089 8.2317 5.95804 7.6817 6.23795 7.20536C7.82411 9.15982 10.2058 10.4366 12.8772 10.5741C12.4205 8.38884 14.0558 6.61607 16.0201 6.61607C16.9482 6.61607 17.783 7.00402 18.3723 7.63259C19.0991 7.49509 19.7964 7.225 20.4152 6.8567C20.1746 7.60312 19.6688 8.2317 19.0009 8.62946C19.6491 8.56071 20.2777 8.37902 20.8571 8.12857C20.4201 8.77187 19.8701 9.34152 19.2415 9.79821Z"
                      fill="black"
                    />
                  </svg>
                </a>
                <a href="">
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.997 9.33177C10.9772 9.33177 9.32888 10.9801 9.32888 13C9.32888 15.0199 10.9772 16.6682 12.997 16.6682C15.0169 16.6682 16.6652 15.0199 16.6652 13C16.6652 10.9801 15.0169 9.33177 12.997 9.33177ZM23.9988 13C23.9988 11.481 24.0125 9.97571 23.9272 8.45943C23.8419 6.69824 23.4402 5.13519 22.1523 3.84732C20.8617 2.55669 19.3014 2.15767 17.5403 2.07237C16.0213 1.98706 14.516 2.00082 12.9998 2.00082C11.4808 2.00082 9.97556 1.98706 8.45931 2.07237C6.69815 2.15767 5.13513 2.55945 3.84728 3.84732C2.55668 5.13794 2.15767 6.69824 2.07237 8.45943C1.98706 9.97846 2.00082 11.4837 2.00082 13C2.00082 14.5163 1.98706 16.0243 2.07237 17.5406C2.15767 19.3018 2.55944 20.8648 3.84728 22.1527C5.13788 23.4433 6.69815 23.8423 8.45931 23.9276C9.97831 24.0129 11.4835 23.9992 12.9998 23.9992C14.5188 23.9992 16.024 24.0129 17.5403 23.9276C19.3014 23.8423 20.8645 23.4406 22.1523 22.1527C23.4429 20.8621 23.8419 19.3018 23.9272 17.5406C24.0153 16.0243 23.9988 14.519 23.9988 13ZM12.997 18.6441C9.87374 18.6441 7.35309 16.1234 7.35309 13C7.35309 9.87664 9.87374 7.35594 12.997 7.35594C16.1203 7.35594 18.641 9.87664 18.641 13C18.641 16.1234 16.1203 18.6441 12.997 18.6441ZM18.8722 8.44292C18.1429 8.44292 17.554 7.85402 17.554 7.12478C17.554 6.39554 18.1429 5.80664 18.8722 5.80664C19.6014 5.80664 20.1903 6.39554 20.1903 7.12478C20.1905 7.29794 20.1565 7.46945 20.0904 7.62947C20.0242 7.78949 19.9271 7.93489 19.8047 8.05733C19.6822 8.17978 19.5369 8.27686 19.3768 8.34303C19.2168 8.40919 19.0453 8.44314 18.8722 8.44292Z"
                      fill="black"
                    />
                  </svg>
                </a>
                <a href="">
                  <svg
                    width="28"
                    height="26"
                    viewBox="0 0 28 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.38226 2C3.95468 2 2 3.79179 2 6.01706V19.9829C2 22.2082 3.95468 24 6.38226 24H14.6398V15.3994H12.1588V12.3029H14.6398V9.65737C14.6398 7.57892 16.1057 5.67056 19.4825 5.67056C20.8497 5.67056 21.8608 5.79088 21.8608 5.79088L21.7813 8.68251C21.7813 8.68251 20.7501 8.67358 19.625 8.67358C18.4073 8.67358 18.212 9.18789 18.212 10.0417V12.3029H21.878L21.7183 15.3994H18.212V24H21.6177C24.0453 24 26 22.2082 26 19.9829V6.01708C26 3.79181 24.0453 2.00002 21.6177 2.00002H6.38223L6.38226 2Z"
                      fill="black"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="h-[60px] border flex items-center justify-center border-black rounded-t-[20px]">
            <p className="text-xs md:text-sm text-center font-[500]">
              © Copyright Zinbi Energies. All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
